import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  ImageEntry,
  MediaImage,
  MediaVideo,
  MetaData,
  VideoEntry,
} from '@services/media-services/models';
import { ActionBtnComponent } from '@enginuity/core/molecules/action-btn/action-btn.component';
import { FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { FormFieldComponent } from '@enginuity/campaign/molecules/form-field/form-field.component';
import { InputsComponent } from '@enginuity/core/molecules/inputs/inputs.component';
import { NgForOf, NgIf } from '@angular/common';
import { LinkBtnComponent } from '@enginuity/core/molecules/tertiary/link-btn.component';
import { ButtonsComponent } from '@enginuity/core/molecules/buttons/buttons.component';
import { DropdownTopHeadingComponent } from '@enginuity/core/organisms/dropdown-top-heading/dropdown-top-heading.component';
import { MediaUploaderComponent } from '@enginuity/product/organisms/media/media-uploader/media-uploader.component';
import { SecondaryBtnsComponent } from '@enginuity/core/molecules/secondary-btns/secondary-btns.component';
import { AvatarComponent } from '@enginuity/core/molecules/avatar/avatar.component';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-media-entry-form',
  imports: [
    ActionBtnComponent,
    ReactiveFormsModule,
    FormFieldComponent,
    InputsComponent,
    NgForOf,
    NgIf,
    LinkBtnComponent,
    ButtonsComponent,
    DropdownTopHeadingComponent,
    MediaUploaderComponent,
    SecondaryBtnsComponent,
    AvatarComponent,
    MatExpansionModule,
  ],
  templateUrl: './media-entry-form.component.html',
  styleUrl: './media-entry-form.component.scss',
})
export class MediaEntryFormComponent implements OnChanges {
  @Input() entry!: MediaImage | MediaVideo | undefined;
  @Input() editable: boolean = false;

  activePanel: boolean = false;

  @Output() onSave = new EventEmitter();
  @Output() onDelete = new EventEmitter<MediaImage | MediaVideo>();
  @Output() onClose = new EventEmitter();

  protected rename: boolean = false;
  protected formMeta = this.fb.group({
    file_name: '',
    title: '',
    alternate_text: '',
    description: '',
    tags: [[]],
    meta_data: this.fb.array([]),
  });

  get meta_data(): FormArray {
    return this.formMeta.get('meta_data') as FormArray;
  }

  constructor(private readonly fb: FormBuilder) {}

  setActivePanel(): void {
    this.activePanel = !this.activePanel;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const entry = changes['entry']?.currentValue;
    this.formMeta.patchValue({
      file_name: entry?.file_name ?? '',
      title: entry?.title ?? '',
      alternate_text: entry?.alternate_text ?? '',
      description: entry?.description ?? '',
      tags: entry.tags || [],
    });

    (entry?.meta_data?.length ? entry?.meta_data : [this.newMetaData()]).forEach((x: MetaData) =>
      this.meta_data.push(this.fb.group(x))
    );
  }

  onDefaultSave() {
    this.onSave.emit(this.formMeta.getRawValue());
  }

  onMetaDataRemove(i: number) {
    this.meta_data.removeAt(i);
  }

  onCancelClick() {
    this.onClose.emit();
  }

  addNewMetaData() {
    this.meta_data.push(this.fb.group(this.newMetaData()));
  }

  handleImageUpload(images: ImageEntry[]) {
    const image = images[0];
    this.onSave.emit({ file_id: image.file_id });
  }

  handleVideoUpload(videos: VideoEntry[]) {
    const video = videos[0];
    this.onSave.emit({ file_id: video.file_id });
  }

  setRenameMode() {
    this.rename = true;
  }

  delete() {
    this.onDelete.emit(this.entry);
  }

  updateName() {
    this.entry!.file_name = this.formMeta.get('file_name')?.value || '';
    this.onSave.emit(this.formMeta.getRawValue());
    this.rename = false;
  }

  cancelRename() {
    this.rename = false;
    this.formMeta.get('file_name')?.setValue(this.entry!.file_name);
  }

  private newMetaData(): { key: string; value: string } {
    return { key: '', value: '' };
  }
}
