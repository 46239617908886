import { TableViewHeader } from '@services/ui-services/models';
import { MediaImage, MediaVideo } from '@services/media-services/models';

type mapMediaTableRow = (MediaImage | MediaVideo) & {
  isSelected?: boolean;
};

type MediaEntryTableHead = Omit<TableViewHeader, 'column'> & {
  column?: keyof mapMediaTableRow;
};

export const MEDIA_ENTRY_TABLE_HEAD_SCHEMA: MediaEntryTableHead[] = [
  {
    type: 'checkbox',
    label: '',
    sortable: false,
    isShow: true,
    sortOrder: 'asc',
    checkbox: true,
    event: 'checkbox',
    isCheckIndeterminate: false,
    disabled: false,
  },
  {
    type: 'string',
    label: 'Image',
    sortable: false,
    isShow: true,
    sortOrder: 'default',
    column: 'url',
  },
  {
    type: 'string',
    label: 'Title',
    sortable: false,
    isShow: true,
    sortOrder: 'default',
    column: 'title',
  },
  {
    type: 'string',
    label: 'Type',
    sortable: true,
    isShow: true,
    sortOrder: 'default',
    column: 'file_type',
  },
  {
    type: 'segments',
    label: 'Used in',
    sortable: false,
    isShow: true,
    sortOrder: 'default',
    column: 'usage',
  },
  {
    type: 'date',
    label: 'Updated',
    sortable: true,
    isShow: true,
    sortOrder: 'default',
    column: 'modified_at',
  },
  {
    type: 'actions',
    label: '',
    sortable: true,
    isShow: true,
    sortOrder: 'default',
  },
];
