import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, switchMap, tap } from 'rxjs';
import {
  Folder,
  FolderNode,
  MediaGalleryView,
  MediaImage,
  MediaVideo,
} from '@services/media-services/models';
import { MediaService } from '@services/media-services/media.service';
import {
  FooterAction,
  Pagination,
  ProcessingState,
  Query,
  Selection,
  Tab,
} from '@services/core-services/models';
import { Dropdown, TableView } from '@services/ui-services/models';
import {
  Conditioner,
  FilterProvider,
  FilterQuery,
  FilterService,
  FilterSetup,
} from '@services/core-services/filters';
import { SelectionService } from '@services/core-services/selection.service';
import {
  mapMediaEntryTableRow,
  MEDIA_ENTRY_TABLE_HEAD_SCHEMA,
  MEDIA_GALLERY_FOOTER_ACTIONS,
} from '@services/media-services/constants';
import { ErrorHandlerService } from '@services/core-services/error-handler.service';

export interface MediaQuery extends Query {
  folder_id?: string;
}

const sortByOptions: Dropdown[] = [
  {
    name: 'Recently created',
    value: 'Recently created',
    icon: 'subscription',
    checkbox: false,
    radio: false,
    showIcon: false,
  },
  {
    name: 'Biggest first',
    value: 'Biggest first',
    icon: 'subscription',
    checkbox: false,
    radio: false,
    showIcon: false,
  },
];

const displayOptions: Array<Tab> = [
  {
    label: '',
    id: 'grid',
    active: true,
    icon: 'microsoft',
  },
  {
    label: '',
    id: 'list',
    active: false,
    icon: 'list',
  },
];

@Injectable({ providedIn: 'root' })
export class MediaGalleryService {
  public errorMessage = new BehaviorSubject<any>('');
  private view = new BehaviorSubject<MediaGalleryView>(MediaGalleryView.List);
  private loading = new BehaviorSubject<boolean>(false);
  private entries = new BehaviorSubject<(MediaImage | MediaVideo)[]>([]);
  private folders = new BehaviorSubject<Folder[]>([]);
  private footerActions = new BehaviorSubject<FooterAction[]>(MEDIA_GALLERY_FOOTER_ACTIONS);
  private pagination = new BehaviorSubject<Pagination | undefined>(undefined);
  private displayOptions = new BehaviorSubject<Tab[]>(displayOptions);
  private sortByOptions = new BehaviorSubject<Dropdown[]>(sortByOptions);
  private display = new BehaviorSubject<Tab>(displayOptions.find(t => t.active)!);
  private sortBy = new BehaviorSubject<Dropdown>(sortByOptions[0]);
  private selectedEntry = new BehaviorSubject<MediaImage | MediaVideo | undefined>(undefined);
  protected MediaLoader = new BehaviorSubject<ProcessingState>(ProcessingState.Loading);
  private selectedFolder = new BehaviorSubject<FolderNode | undefined>({
    id: 'root',
    name: 'All assets',
    folders: [],
  });
  private filterSetup = new BehaviorSubject<FilterSetup | undefined>(undefined);
  private query = new BehaviorSubject<MediaQuery>({ limit: 100, filters: [] } as MediaQuery);
  private table = new BehaviorSubject<TableView>({
    head: MEDIA_ENTRY_TABLE_HEAD_SCHEMA,
    rows: [],
    data: [],
  });

  constructor(
    private readonly mediaService: MediaService,
    protected readonly selectionService: SelectionService,
    private readonly filtersService: FilterService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  getLoading() {
    return this.loading.asObservable();
  }

  getMediaLoader(): Observable<ProcessingState> {
    return this.MediaLoader.asObservable();
  }

  getTable() {
    return this.table.asObservable();
  }

  getFolders() {
    return this.folders.asObservable();
  }

  getPagination() {
    return this.pagination.asObservable();
  }

  getSortByOptions() {
    return this.sortByOptions.asObservable();
  }

  getDisplayOptions() {
    return this.displayOptions.asObservable();
  }

  getSelection(): Observable<Selection<MediaVideo | MediaImage>> {
    return this.selectionService.get<MediaVideo | MediaImage>();
  }

  clearSelection() {
    this.selectionService.clearSelection();
  }

  getFooterActions(): Observable<FooterAction[]> {
    return this.footerActions;
  }

  getDisplay() {
    return this.display.asObservable();
  }

  getSortBy() {
    return this.sortBy.asObservable();
  }

  getView() {
    return this.view.asObservable();
  }

  getSelectedFolder() {
    return this.selectedFolder.asObservable();
  }

  getSelectedEntry() {
    return this.selectedEntry.asObservable();
  }

  getFilterSetup() {
    return this.filterSetup.asObservable();
  }

  getEntries() {
    return this.entries.asObservable();
  }

  setView(view: MediaGalleryView) {
    this.view.next(view);
  }

  loadFolders() {
    return this.mediaService.getFolders().pipe(
      tap(res => {
        this.folders.next(res.list);
      })
    );
  }

  loadEntries() {
    this.MediaLoader.next(ProcessingState.Loading);
    this.loading.next(true);
    const query = this.query.getValue();
    return this.mediaService.getEntries(query).pipe(
      tap(res => {
        this.MediaLoader.next(ProcessingState.Success);
        this.loading.next(false);
        const table = this.table.getValue();
        this.entries.next(res.list);
        this.pagination.next(res.pagination);
        this.table.next({ ...table, rows: res.list.map(mapMediaEntryTableRow) });
      }),
      catchError(error => {
        this.MediaLoader.next(ProcessingState.Error);
        const errorMessage = this.errorHandlerService.handleServerError(error);
        this.errorMessage.next(errorMessage);
        // this.errorMessage.next(error || 'An error occurred');
        return [];
      })
    );
  }

  createFolder(folder: Folder) {
    const folders = this.folders.getValue();
    return this.mediaService
      .createFolder(folder)
      .pipe(tap(folder => this.folders.next([...folders, folder])));
  }

  updateFolder(id: string, folder: Folder) {
    const folders = this.folders.getValue();
    return this.mediaService
      .updateFolder(id, folder)
      .pipe(tap(folder => this.folders.next(folders.map(f => (f.id === id ? folder : f)))));
  }

  selectFolder(folder: FolderNode) {
    const current = this.selectedFolder.getValue();
    if (this.mediaService.isRootFolder(folder.id)) {
      this.selectedFolder.next(folder);
    } else {
      this.selectedFolder.next(current?.id === folder?.id ? undefined : folder);
    }

    const query = this.query.getValue();
    if (folder && !this.mediaService.isRootFolder(folder.id) && folder.id !== current?.id) {
      query.folder_id = folder.id;
    } else {
      // @ts-ignore
      delete query.folder_id;
    }

    this.query.next(query);
  }

  setupFilter(provider: FilterProvider) {
    return this.filtersService.getSetup(provider).pipe(tap(setup => this.filterSetup.next(setup)));
  }

  applyFilter(filters: FilterQuery) {
    const query = this.query.getValue();
    this.query.next({ ...query, filters });
    return this.loadEntries();
  }

  applySearch(search: string) {
    const query = this.query.getValue();
    this.query.next({ ...query, search });
    return this.loadEntries();
  }

  setDisplay(id: string) {
    const options = this.displayOptions.getValue();
    const tab = options.find(o => o.id === id);
    if (tab) {
      this.display.next(tab);
    }
  }

  selectEntry(entry: MediaImage | MediaVideo | undefined) {
    this.selectedEntry.next(entry);
    if (entry) {
      this.view.next(MediaGalleryView.Entry);
    } else {
      this.view.next(MediaGalleryView.List);
    }
  }

  checkEntry(entry: MediaImage | MediaVideo | undefined, flag: boolean) {
    if (!entry) {
      flag
        ? this.selectionService.updateSelection(this.entries.getValue(), true)
        : this.selectionService.clearSelection();
      return;
    }

    if (flag) {
      this.selectionService.add<MediaImage | MediaVideo>(entry!);
    } else {
      this.selectionService.remove<MediaImage | MediaVideo>(entry!);
    }
  }

  updateEntry(id: string, entry: MediaImage | MediaVideo) {
    return this.mediaService.updateEntry(id, entry).pipe(
      tap(entry => {
        const entries = this.entries.getValue();
        const query: Query = { filters: { condition: Conditioner.AND, filters: [] } };
        this.entries.next(entries.map(e => (e.id === id ? entry : e)));
        this.query.next(query);
        return entry;
      })
    );
  }

  deleteEntry(id: string) {
    return this.mediaService.deleteEntry(id).pipe(
      tap(() => {
        const entries = this.entries.getValue();
        this.entries.next(entries.filter(e => e.id !== id));
      })
    );
  }

  addEntries(entries: (MediaImage | MediaVideo)[]) {
    const list = this.entries.getValue();
    this.entries.next([...entries, ...list]);
  }

  deleteCheckedEntries() {
    const { list } = this.selectionService.getSnapshot<MediaVideo | MediaImage>();
    return this.mediaService.deleteMultipleEntries(list).pipe(
      tap(() => {
        const entries = this.entries.getValue();
        this.entries.next(entries.filter(e => !list.some(x => x.id === e.id)));
        this.selectionService.clearSelection();
      })
    );
  }

  moveCheckedEntries(folderId: string) {
    const { list } = this.selectionService.getSnapshot<MediaVideo | MediaImage>();
    return this.mediaService.moveMultipleEntries(folderId, list).pipe(
      switchMap(() => this.loadEntries()),
      tap(() => this.selectionService.clearSelection())
    );
  }

  setPage(page: number) {
    const q = this.query.getValue();
    const query = { ...q, page };
    this.query.next(query);
    return this.loadEntries();
  }
}
