<div class="header">
  <h3 class="h3-bold primary-100">Asset details</h3>
  <app-action-btn
    [active]="false"
    [IconType]="'cancel'"
    (custom_click)="onCancelClick()"></app-action-btn>
</div>
<div [formGroup]="formMeta" class="main modal-slide custom-scroll">
  <div class="actions">
    <ng-container *ngIf="!rename && editable">
      <h3 class="h3-bold img-text">
        {{ entry?.file_name }}
      </h3>
      <div class="flex-grow-1"></div>

      <app-secondary-btns
        size="small"
        label="Rename"
        IconValue=""
        (onClick)="setRenameMode()"></app-secondary-btns>
      <app-buttons
        size="small"
        State="alert"
        [alert]="true"
        [active]="false"
        label="Delete"
        IconType="delete"
        [shortcutKey]="false"
        (btnClick)="delete()"></app-buttons>
    </ng-container>

    <ng-container *ngIf="rename">
      <div class="flex-grow-1 w-full">
        <app-inputs
          formControlName="file_name"
          Label=""
          [value]="entry?.file_name || ''"></app-inputs>
      </div>
      <div class="flex-grow-1"></div>
      <app-action-btn
        (custom_click)="updateName()"
        IconType="check"
        [active]="false"></app-action-btn>
      <app-action-btn
        (custom_click)="cancelRename()"
        IconType="cancel"
        [active]="false"></app-action-btn>
    </ng-container>
  </div>

  <div class="modal-slide">
    <div class="accordian">
      <mat-accordion [multi]="false" class="example-headers-align">
        <mat-expansion-panel [expanded]="setActivePanel()">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <app-dropdown-top-heading
                [count]="false"
                accordian_heading="Default metadata"
                icon="bank"
                [background]="'primary-80'">
              </app-dropdown-top-heading>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div>
            <p class="h4-reg primary-100">
              When you reference this asset inside a record without providing any specific metadata,
              these values will be used as a fallback.
            </p>
            <app-form-field>
              <app-inputs formControlName="title" Label="Title/Caption" placeholder=""></app-inputs>
            </app-form-field>
            <app-form-field>
              <app-inputs
                formControlName="alternate_text"
                Label="Alternate text"
                placeholder=""></app-inputs>
            </app-form-field>
            <app-form-field>
              <app-inputs
                formControlName="description"
                Label="Description"
                placeholder=""></app-inputs>
            </app-form-field>
            <app-form-field class="d-flex flex-column spacing-12">
              <h4 class="h4-bold primary-60 mb-2">Custom data</h4>
              <div formArrayName="meta_data" class="d-flex flex-column spacing-12">
                <ng-container *ngFor="let data of meta_data.controls; let i = index">
                  <div
                    class="d-flex spacing-16 align-items-center justify-content-start"
                    [formGroupName]="i">
                    <app-inputs formControlName="key" Label="" placeholder="Name"></app-inputs>
                    <app-inputs formControlName="value" Label="" placeholder="Value"></app-inputs>
                    <app-action-btn
                      [active]="false"
                      IconType="cancel"
                      (custom_click)="onMetaDataRemove(i)">
                    </app-action-btn>
                  </div>
                </ng-container>
              </div>
            </app-form-field>
            <app-form-field>
              <app-link-btn label="Add new" (custom_click)="addNewMetaData()"></app-link-btn>
            </app-form-field>
            <app-form-field>
              <app-buttons
                [shortcutKey]="false"
                [Type]="'withoutIcon'"
                label="Save"
                (btnClick)="onDefaultSave()">
              </app-buttons>
            </app-form-field>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="setActivePanel()">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <app-dropdown-top-heading
                [count]="false"
                accordian_heading="Categorization"
                icon="balance"
                [background]="'primary-80'">
              </app-dropdown-top-heading>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-form-field>
            <app-inputs
              [multiSelectTags]="true"
              formControlName="tags"
              Label="Manual tags"
              placeholder="">
            </app-inputs>
          </app-form-field>
          <app-form-field>
            <app-buttons
              [shortcutKey]="false"
              [Type]="'withoutIcon'"
              label="Save"
              (btnClick)="onDefaultSave()">
            </app-buttons>
          </app-form-field>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="setActivePanel()">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <app-dropdown-top-heading
                [count]="false"
                accordian_heading="Creator"
                icon="customer"
                [background]="'primary-80'">
              </app-dropdown-top-heading>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="h4-bold d-flex spacing-8 align-items-center">
            <app-avatar userImg="user"></app-avatar>
            {{ entry?.created_by || 'Unknown' }}
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="setActivePanel()">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <app-dropdown-top-heading
                [count]="false"
                accordian_heading="Replace asset"
                icon="withdrawal"
                [background]="'primary-80'">
              </app-dropdown-top-heading>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-media-uploader
            [multiple]="false"
            (onImagesUpload)="handleImageUpload($event)"
            (onVideosUpload)="handleVideoUpload($event)">
          </app-media-uploader>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
