import { FooterAction } from '@services/core-services/models';

export const MEDIA_GALLERY_FOOTER_ACTIONS: FooterAction[] = [
  {
    key: 'move',
    label: 'Move selected',
  },
  {
    key: 'delete',
    label: 'Delete selected',
    icon: 'trash',
    variant: 'alert',
  },
];
