import { MEDIA_ENTRY_TABLE_HEAD_SCHEMA } from '@services/media-services/constants';
import { MediaImage, MediaVideo } from '@services/media-services/models';

type MediaEntryTableRow = (MediaImage | MediaVideo) & {
  isSelected?: boolean;
};

export const mapMediaEntryTableRow = (entry: MediaEntryTableRow) => {
  let index = 0;
  return [
    {
      column: MEDIA_ENTRY_TABLE_HEAD_SCHEMA[index++].column,
      type: 'checkbox',
      label: entry.id,
      isChecked: entry.isSelected,
      event: 'row-checkbox',
      data: entry,
    },
    {
      type: 'product',
      product: {
        product_img: entry.url.image_thumbnail,
        product_name: '',
        color: '',
        strike: '',
      },
    },
    {
      column: MEDIA_ENTRY_TABLE_HEAD_SCHEMA[index++].column,
      type: 'text',
      text: {
        title: entry.title || entry.id,
      },
    },
    {
      column: MEDIA_ENTRY_TABLE_HEAD_SCHEMA[index++].column,
      type: 'text',
      text: {
        title: entry.file_type,
      },
    },
    {
      column: MEDIA_ENTRY_TABLE_HEAD_SCHEMA[index++].column,
      type: 'segments',
      segments: entry.usage.map(usage => ({
        segmentName: usage.used_in,
      })),
    },
    {
      column: MEDIA_ENTRY_TABLE_HEAD_SCHEMA[index++].column,
      type: 'date',
      date: {
        title: entry.modified_at,
        format: 'dd MMM, yyyy hh:mm:ss a',
      },
    },
    {
      type: 'actions',
      actions: [
        {
          icon: 'chevron_right',
          text: '',
          active: false,
          action: 'open-detail',
          action_id: entry,
          value: entry,
        },
      ],
    },
  ];
};
