import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MediaImage, MediaVideo } from '@services/media-services/models';
import { CloudflareStreamModule } from '@cloudflare/stream-angular';

@Component({
  selector: 'app-media-video-player',
  imports: [CloudflareStreamModule],
  templateUrl: './media-video-player.component.html',
  styleUrl: './media-video-player.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class MediaVideoPlayerComponent {
  @Input() entry!: MediaVideo | MediaImage;
}
