import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { SecondaryBtnsComponent } from '@enginuity/core/molecules/secondary-btns/secondary-btns.component';
import { MediaImage, MediaItemFileType, MediaVideo } from '@services/media-services/models';
import { DatePipe, NgIf, NgOptimizedImage } from '@angular/common';
import { FileSizePipe } from '@services/media-services/pipes/file-size.pipe';
import { MediaEntryFormComponent } from '@enginuity/media/organisms/media-entry-form/media-entry-form.component';
import { MediaGalleryService } from '@services/media-services/media-gallery.service';
import { NotifyService } from '@services/core-services/notify.service';
import { DownloadService } from '@services/core-services/download.service';
import { Subscription } from 'rxjs';
import { MediaVideoPlayerComponent } from '@enginuity/media/organisms/media-video-player/media-video-player.component';
import { BadgesComponent } from '@enginuity/core/molecules/badges/badges.component';

@Component({
  selector: 'app-media-gallery-entry',
  templateUrl: './media-gallery-entry.component.html',
  styleUrl: './media-gallery-entry.component.scss',
  imports: [
    SecondaryBtnsComponent,
    NgIf,
    NgOptimizedImage,
    DatePipe,
    FileSizePipe,
    MediaEntryFormComponent,
    MediaVideoPlayerComponent,
    BadgesComponent,
  ],
})
export class MediaGalleryEntryComponent implements OnDestroy {
  protected readonly MediaItemFileType = MediaItemFileType;
  private subscriptions: Subscription[] = [];

  @Input() entry: MediaImage | MediaVideo | undefined | null;
  @Input() editable: boolean = false;

  @Output() onClose = new EventEmitter();
  @Output() onDelete = new EventEmitter();

  constructor(
    private readonly clipboard: Clipboard,
    private readonly notify: NotifyService,
    private readonly downloadService: DownloadService,
    private readonly mediaGalleryService: MediaGalleryService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onEntrySave(entry: any) {
    const id = this.entry?.id;
    this.mediaGalleryService.updateEntry(id!, entry).subscribe(() => {
      this.notify.success('Media entry successfully updated');
    });
  }

  onEntryDelete(entry: MediaImage | MediaVideo) {
    const id = entry.id;
    this.mediaGalleryService.deleteEntry(id).subscribe(() => {
      this.notify.success('Media entry successfully deleted');
      this.onDelete.emit();
    });
  }

  onEntryClose() {
    this.onClose.emit();
  }

  copyUrl() {
    this.clipboard.copy(this.entry?.url.image_original!);
    this.notify.success('Url of the asset copied to clipboard.');
  }

  download() {
    this.subscriptions.push(
      this.downloadService
        .getAndDownload(this.entry?.url.image_original!, {
          type: this.entry!.mime_type as string,
          fileName: this.entry?.file_name!,
        })
        .subscribe()
    );
  }
}
