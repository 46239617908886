<div class="media-view" *ngIf="entry">
  <div class="media-entry">
    <div class="media-entry-actions">
      <!--<app-secondary-btns Type="leftIcon" size="small" label="Edit image" IconValue=""
                          [IconType]="'edit'"></app-secondary-btns>-->
      <app-secondary-btns
        Type="leftIcon"
        size="small"
        label="Copy URL"
        IconValue=""
        [IconType]="'duplicate'"
        (onClick)="copyUrl()"></app-secondary-btns>
      <app-secondary-btns
        Type="leftIcon"
        size="small"
        label="Download"
        IconValue=""
        [IconType]="'download'"
        (onClick)="download()"></app-secondary-btns>
    </div>
    <div class="media-entry-view">
      <div class="media-entry-view-image" *ngIf="entry.file_type === MediaItemFileType.Image">
        <img [ngSrc]="entry.url.image_standard" alt="" fill />
      </div>
      <div class="media-entry-view-video" *ngIf="entry.file_type === MediaItemFileType.Video">
        <app-media-video-player [entry]="entry"></app-media-video-player>
      </div>
    </div>
    <div class="media-entry-footer">
      <div>
        <div class="media-entry-property">
          <label class="label h4-reg">File ID</label>
          <app-badges
            [withIcon]="true"
            [Type]="'info'"
            [IconType]="'clipboard'"
            [counter]="false"
            [close]="false"
            [onlyIcon]="false"
            [uppercase]="true"
            [label]="entry.id"
            [ellipsis]="false"></app-badges>
        </div>
        <div class="media-entry-property">
          <label class="label h4-reg">MIME Type</label>
          <label class="value h4-bold">{{ entry.mime_type }}</label>
        </div>
        <div class="media-entry-property">
          <label class="label h4-reg">Created at</label>
          <label class="value h4-bold">{{
            entry.created_at | date: 'dd MMM, yyyy hh:mm:ss a'
          }}</label>
        </div>
        <div class="media-entry-property">
          <label class="label h4-reg">Updated at</label>
          <label class="value h4-bold">{{
            entry.modified_at | date: 'dd MMM, yyyy hh:mm:ss a'
          }}</label>
        </div>
      </div>
      <div>
        <div class="media-entry-property">
          <label class="label h4-reg">Title</label>
          <label class="value h4-bold">{{ entry.title || '-' }}</label>
        </div>
        <div class="media-entry-property">
          <label class="label h4-reg">File size</label>
          <label class="value h4-bold">{{ entry.file_size | fileSize }}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="media-entry-form">
    <app-media-entry-form
      [editable]="editable"
      [entry]="entry"
      (onSave)="onEntrySave($event)"
      (onDelete)="onEntryDelete($event)"
      (onClose)="onEntryClose()"></app-media-entry-form>
  </div>
</div>
