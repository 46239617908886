<div class="d-flex flex-column spacing-16">
  <app-box-tab [boxContent]="tabs" (selectedTab)="onSourceTypeChange($event)"> </app-box-tab>

  <ng-container *ngIf="sourceType === SourceType.Upload">
    <app-file
      [state]="'default'"
      (onChange)="handleFileChange($event)"
      [sizeLabel]="sizeLabel"
      [isMultiple]="multiple"></app-file>
  </ng-container>

  <ng-container *ngIf="sourceType === SourceType.External">
    <div class="d-flex flex-column spacing-4">
      <div class="d-flex spacing-4 align-items-end">
        <div class="flex-grow-1">
          <app-inputs
            Label="External url"
            placeholder="Provide external url"
            [formControl]="externalUrlCtrl"></app-inputs>
        </div>
        <app-buttons
          (btnClick)="uploadFromExternalUrl()"
          [disabled]="!externalUrl || invalidUrl"
          [IconType]="''"
          label="Upload"></app-buttons>
      </div>
      <span class="alert-140 h4-reg" *ngIf="invalidUrl">Url provided is not valid</span>
    </div>
  </ng-container>
  <!-- Images Preview -->
  <app-media-gallery-box
    [images]="imageThumbnails"
    (onSort)="onImageListSort($event)"
    (onDelete)="onDeleteImage($event)"></app-media-gallery-box>
  <!-- Videos Preview -->
  <app-media-gallery-box
    [images]="videoThumbnails"
    (onSort)="onVideoListSort($event)"
    (onDelete)="onDeleteVideo($event)"></app-media-gallery-box>
</div>
